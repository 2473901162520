        import * as React from 'react';
        import { Box, Card, CardContent, CardActions, Button, Typography, Grid, Divider, ThemeProvider, createTheme } from '@mui/material';
        import { styled } from '@mui/material/styles';
        import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
        import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
        import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

        const theme = createTheme({
    palette: {
        primary: {
        main: '#1976d2',
        },
        secondary: {
        main: '#f50057',
        },
        background: {
        default: '#f5f5f5',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
        fontWeight: 700,
        color: '#333',
        },
    },
    shape: {
        borderRadius: 12,
    },
    });
        const pricingData = [
        {
        modelName: 'Felt Upholstered Chair',
        price: '₹15,000',
        description: 'A stylish upholstered chair made of felt and wood, perfect for living rooms and offices.',
        priceHistory: [
        { month: 'Jan', price: 16000 },
        { month: 'Feb', price: 15500 },
        { month: 'Mar', price: 15000 },
        { month: 'Apr', price: 15000 },
        { month: 'May', price: 14800 },
        { month: 'Jun', price: 15000 },
        ],
    },
    {
        modelName: 'Foam-Steel Construction Designer Chair',
        price: '₹5,000',
        description: 'A designer steel frame chair, perfect for Living and Drawing room.',
        priceHistory: [
        { month: 'Jan', price: 5500 },
        { month: 'Feb', price: 5300 },
        { month: 'Mar', price: 5200 },
        { month: 'Apr', price: 5000 },
        { month: 'May', price: 5000 },
        { month: 'Jun', price: 5000 },
        ],
    },
    {
        modelName: 'Gaming Chair',
        price: '₹26,000',
        description: 'Great, Ergonomic and the most comfortable Gaming Chair',
        priceHistory: [
        { month: 'Jan', price: 28000 },
        { month: 'Feb', price: 27500 },
        { month: 'Mar', price: 27000 },
        { month: 'Apr', price: 26500 },
        { month: 'May', price: 26000 },
        { month: 'Jun', price: 26000 },
        ],
    },
    {
        modelName: 'Sofa',
        price: '₹45,000',
        description: 'Comfortable and spacious Sofa, Ideal for Living Room Decor.',
        priceHistory: [
        { month: 'Jan', price: 48000 },
        { month: 'Feb', price: 47000 },
        { month: 'Mar', price: 46000 },
        { month: 'Apr', price: 45500 },
        { month: 'May', price: 45000 },
        { month: 'Jun', price: 45000 },
        ],
    },
    ];

    const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    height: '100%',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-10px)',
        boxShadow: theme.shadows[10],
    },
    background: 'linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%)',
    borderRadius: theme.shape.borderRadius * 2,
    }));

    const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius * 2,
    textTransform: 'none',
    fontWeight: 600,
    boxShadow: theme.shadows[2],
    '&:hover': {
        boxShadow: theme.shadows[4],
    },
    }));

    const PriceHistoryChart = ({ data }) => (
    <ResponsiveContainer width="100%" height={200}>
        <LineChart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="price" stroke="#8884d8" strokeWidth={2} dot={{ r: 4 }} />
        </LineChart>
    </ResponsiveContainer>
    );

    export default function Pricing() {
    return (
        <ThemeProvider theme={theme}>
        <Box sx={{ py: 8, px: 4, backgroundColor: 'background.default', minHeight: '100vh' }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ mb: 4 }}>
            Exclusive Furniture Pricing
            </Typography>
            <Typography variant="body1" align="center" gutterBottom sx={{ mb: 6, maxWidth: 600, mx: 'auto' }}>
            Discover our premium furniture collection with competitive prices. 
            Check out the price history to make an informed decision!
            </Typography>
            <Grid container spacing={4}>
            {pricingData.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                <StyledCard>
                    <CardContent>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                        {item.modelName}
                    </Typography>
                    <Typography variant="h6" color="secondary.main" sx={{ mb: 2 }}>
                        {item.price}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {item.description}
                    </Typography>
                    <Typography variant="subtitle2" color="primary.main" sx={{ mb: 1 }}>
                        Price History
                    </Typography>
                    <PriceHistoryChart data={item.priceHistory} />
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'space-between', mt: 2 }}>
                    <StyledButton 
                        variant="outlined" 
                        startIcon={<ShoppingCartIcon />} 
                        size="small"
                        color="primary"
                    >
                        Add to Cart
                    </StyledButton>
                    <StyledButton 
                        variant="contained" 
                        color="secondary" 
                        startIcon={<MonetizationOnIcon />} 
                        size="small"
                    >
                        Buy Now
                    </StyledButton>
                    </CardActions>
                </StyledCard>
                </Grid>
            ))}
            </Grid>
        </Box>
        </ThemeProvider>
    );
    }
        
