import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import RssFeedRoundedIcon from '@mui/icons-material/RssFeedRounded';
import { AccessTime, LocalOffer, Stars, Home } from '@mui/icons-material';
import CssBaseline from '@mui/material/CssBaseline';

const cardData = [
  {
    img: 'https://images.unsplash.com/photo-1524758631624-e2822e304c36?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    modelName: 'Felt Upholstered Chair',
    materials: ['Felt', 'Wood'],
    brand: 'Pepperfry',
    purchaseDate: { month: 'May', year: '2021' },
    yearsOfOwnership: 3,
    usageCondition: 'Heavy use',
    description: 'A stylish upholstered chair made of felt and wood, perfect for living rooms and offices.',
    priceOfPurchase: '₹15,000',
    purchasedFrom: 'Pepperfry Store',
    firstOwner: true,
    authors: [
      { name: 'John Doe', avatar: 'https://randomuser.me/api/portraits/men/1.jpg' },
    ],
  },
  {
    img: 'https://images.unsplash.com/photo-1501045661006-fcebe0257c3f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    modelName: 'Foam-Steel Construction Designer Chair',
    materials: ['Steel', 'Foam'],
    brand: 'Greenseat',
    purchaseDate: { month: 'May', year: '2021' },
    yearsOfOwnership: 3,
    usageCondition: 'Moderate Use',
    description: 'A designer steel frame chair, perfect for Living and Drawing room.',
    priceOfPurchase: '₹5,000',
    purchasedFrom: 'Godrej Interio',
    firstOwner: true,
    authors: [
      { name: 'John Doe', avatar: 'https://randomuser.me/api/portraits/men/1.jpg' },
    ],
  },
  {
    img: 'https://images.unsplash.com/photo-1619725002198-6a689b72f41d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    modelName: 'Gaming Chair',
    materials: ['ABS', 'Polyester'],
    brand: 'Secret Lab',
    purchaseDate: { month: 'May', year: '2021' },
    yearsOfOwnership: 3,
    usageCondition: 'Mint Condition',
    description: 'Great, Ergonomic and the most comfortable Gaming Chair',
    priceOfPurchase: '₹26,000',
    purchasedFrom: 'Amazon.in',
    firstOwner: true,
    authors: [
      { name: 'John Doe', avatar: 'https://randomuser.me/api/portraits/men/1.jpg' },
    ],
  },
  {
    img: 'https://images.unsplash.com/photo-1540574163026-643ea20ade25?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    modelName: 'Sofa',
    materials: ['Leather', 'Wood'],
    brand: 'IKEA Home Decor',
    purchaseDate: { month: 'May', year: '2021' },
    yearsOfOwnership: 3,
    usageCondition: 'Fine condition',
    description: 'Comfortable and spacious Sofa, Ideal for Living Room Decor.',
    priceOfPurchase: '₹45,000',
    purchasedFrom: 'IKEA',
    firstOwner: true,
    authors: [
      { name: 'John Doe', avatar: 'https://randomuser.me/api/portraits/men/1.jpg' },
    ],
  },
];

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  '&:focus-visible': {
    outline: '3px solid',
    outlineColor: 'hsla(210, 98%, 48%, 0.5)',
    outlineOffset: '2px',
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: 16,
  flexGrow: 1,
  '&:last-child': {
    paddingBottom: 16,
  },
}));

const AttributeChip = styled(Chip)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

function Author({ authors }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
      <AvatarGroup max={3}>
        {authors.map((author, index) => (
          <Avatar key={index} alt={author.name} src={author.avatar} sx={{ width: 24, height: 24 }} />
        ))}
      </AvatarGroup>
      <Typography variant="caption">{authors.map((author) => author.name).join(', ')}</Typography>
    </Box>
  );
}

Author.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export function Search() {
  return (
    <FormControl sx={{ width: { xs: '100%', md: '25ch' } }} variant="outlined">
      <OutlinedInput
        size="small"
        id="search"
        placeholder="Search…"
        startAdornment={
          <InputAdornment position="start" sx={{ color: 'text.primary' }}>
            <SearchRoundedIcon fontSize="small" />
          </InputAdornment>
        }
        inputProps={{
          'aria-label': 'search',
        }}
      />
    </FormControl>
  );
}

export default function MainContent() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <div>
        {/* Page Title */}
        <Typography 
          variant="h3" // Changed from h1 for a more balanced size
          gutterBottom 
          sx={{ fontWeight: 700 }} // Bold heading
        >
          Products
        </Typography>
        <Typography 
          sx={{ color: 'text.secondary', fontWeight: 400 }} // Subheading in lighter color
        >
          Stay in the loop with the latest about our products
        </Typography>
      </div>

      <Box
        sx={{
          display: { xs: 'flex', sm: 'none' },
          flexDirection: 'row',
          gap: 1,
          width: { xs: '100%', md: 'fit-content' },
          overflow: 'auto',
        }}
      >
        <Search />
        <IconButton size="small" aria-label="RSS feed">
          <RssFeedRoundedIcon />
        </IconButton>
      </Box>

      {/* Grid for Cards */}
      <Grid container spacing={2}>
        {cardData.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <StyledCard>
              {/* Card Image */}
              <CardMedia
                component="img"
                alt={item.modelName}
                image={item.img}
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                }}
              />
              
              {/* Card Content */}
              <StyledCardContent>
                <Typography 
                  gutterBottom 
                  variant="h6" // Used a heading style
                  sx={{ fontWeight: 600 }} // Subtle weight for product name
                >
                  {item.modelName}
                </Typography>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {/* Attribute Chips */}
                  <AttributeChip icon={<LocalOffer />} label={item.priceOfPurchase} />
                  <AttributeChip icon={<AccessTime />} label={`${item.yearsOfOwnership} years`} />
                  <AttributeChip icon={<Home />} label={item.purchasedFrom} />
                  <AttributeChip icon={<Stars />} label={item.usageCondition} />
                </Box>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {item.description}
                </Typography>

                {/* Author Component */}
                <Author authors={item.authors} />
              </StyledCardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
//   );
// }
