    import React, { useState } from 'react';
    import { Box, Button, TextField, MenuItem, Typography, Grid, FormControlLabel, Checkbox, Avatar, Paper,ThemeProvider,createTheme } from '@mui/material';
    import { styled } from '@mui/material/styles';
    import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
    import UploadFileIcon from '@mui/icons-material/UploadFile';

    const materialsOptions = ['Leather', 'Wood', 'Steel', 'Felt', 'ABS', 'Polyester'];
    const usageOptions = ['Heavy use', 'Moderate use', 'Mint condition'];

        const theme = createTheme({
    palette: {
        primary: {
        main: '#6200EA',
        },
        secondary: {
        main: '#03DAC6',
        },
        background: {
        default: '#f0f4ff',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h3: {
        fontWeight: 700,
        color: '#3f51b5',
        },
    },
    shape: {
        borderRadius: 12,
    },
    });
    
    const StyledFormContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    maxWidth: 800,
    margin: 'auto',
    padding: theme.spacing(5),
    background: 'linear-gradient(135deg, #ffffff, #f0f4ff)',
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    position: 'relative',
    }));

    const StyledField = styled(TextField)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {
        borderRadius: theme.shape.borderRadius,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
        borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
        },
    },
    '& .MuiInputLabel-root': {
        fontSize: '1.1rem',
        color: theme.palette.text.secondary,
    },
    }));

    const StyledButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius * 2,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1.1rem',
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
    },
    boxShadow: theme.shadows[4],
    }));

    const UploadContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: `2px dashed ${theme.palette.primary.light}`,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  marginBottom: theme.spacing(3),
  transition: 'all 0.3s',
  backgroundColor: theme.palette.background.default,
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
    },
    }));

    const ProductListingForm = () => {
    const [formData, setFormData] = useState({
        image: null,
        modelName: '',
        material: '',
        brandName: '',
        month: '',
        year: '',
        yearsOfOwnership: '',
        usageCondition: '',
        description: '',
        price: '',
        purchasedFrom: '',
        firstOwner: false,
    });

    const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleImageUpload = (e) => {
        setFormData((prev) => ({ ...prev, image: e.target.files[0] }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
    };

    return (
        <ThemeProvider theme={theme}>
        <Box sx={{ padding: 4, backgroundColor: 'background.default', minHeight: '100vh' }}>
            <StyledFormContainer component="form" onSubmit={handleSubmit} elevation={3}>
            <Typography variant="h3" align="center" gutterBottom>
                List Your Product
            </Typography>

            <UploadContainer>
                <UploadFileIcon fontSize="large" sx={{ color: 'primary.main', marginBottom: 2, fontSize: 48 }} />
                <Typography variant="body1" sx={{ marginBottom: 2, color: 'text.secondary' }}>
                Upload a high-quality image of your product
                </Typography>
                <Button
                variant="contained"
                component="label"
                startIcon={<FileUploadRoundedIcon />}
                color="secondary"
                size="large"
                >
                Upload Image
                <input type="file" hidden onChange={handleImageUpload} />
                </Button>
            </UploadContainer>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                <StyledField
                    label="Model Name"
                    variant="outlined"
                    fullWidth
                    name="modelName"
                    value={formData.modelName}
                    onChange={handleInputChange}
                    required
                />
                </Grid>
                <Grid item xs={12} md={6}>
                <StyledField
                    select
                    label="Material"
                    variant="outlined"
                    fullWidth
                    name="material"
                    value={formData.material}
                    onChange={handleInputChange}
                    required
                >
                    {materialsOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                    ))}
                </StyledField>
                </Grid>
            </Grid>

            <StyledField
                label="Brand Name"
                variant="outlined"
                fullWidth
                name="brandName"
                value={formData.brandName}
                onChange={handleInputChange}
                required
            />

            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                <StyledField
                    label="Month of Purchase"
                    variant="outlined"
                    fullWidth
                    name="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    required
                />
                </Grid>
                <Grid item xs={12} sm={4}>
                <StyledField
                    label="Year of Purchase"
                    variant="outlined"
                    fullWidth
                    name="year"
                    value={formData.year}
                    onChange={handleInputChange}
                    required
                />
                </Grid>
                <Grid item xs={12} sm={4}>
                <StyledField
                    label="Years of Ownership"
                    variant="outlined"
                    fullWidth
                    name="yearsOfOwnership"
                    value={formData.yearsOfOwnership}
                    onChange={handleInputChange}
                    required
                />
                </Grid>
            </Grid>

            <StyledField
                select
                label="Usage Condition"
                variant="outlined"
                fullWidth
                name="usageCondition"
                value={formData.usageCondition}
                onChange={handleInputChange}
                required
            >
                {usageOptions.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
                ))}
            </StyledField>

            <StyledField
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
            />

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                <StyledField
                    label="Price of Purchase (₹)"
                    variant="outlined"
                    fullWidth
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                    required
                />
                </Grid>
                <Grid item xs={12} md={6}>
                <StyledField
                    label="Purchased From"
                    variant="outlined"
                    fullWidth
                    name="purchasedFrom"
                    value={formData.purchasedFrom}
                    onChange={handleInputChange}
                    required
                />
                </Grid>
            </Grid>

            <FormControlLabel
                control={
                <Checkbox
                    checked={formData.firstOwner}
                    onChange={(e) => setFormData((prev) => ({ ...prev, firstOwner: e.target.checked }))}
                    name="firstOwner"
                    color="primary"
                />
                }
                label="First Owner"
            />

            <StyledButton type="submit" variant="contained" fullWidth size="large">
                Submit Product
            </StyledButton>
            </StyledFormContainer>
        </Box>
        </ThemeProvider>
    );
    };


    export default ProductListingForm;
