    import React from 'react';
    import { Box, Typography } from '@mui/material';
    import { styled } from '@mui/material/styles';

    const StyledPrivacyContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 800,
    margin: 'auto',
    padding: theme.spacing(5),
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.1))', // gradient background
    backdropFilter: 'blur(15px)', // acrylic effect
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0px 8px 32px rgba(31, 38, 135, 0.37)', // deep shadow
    border: '1px solid rgba(255, 255, 255, 0.18)', // border for frosted effect
    overflow: 'hidden',
    position: 'relative',
    }));

    const StyledTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    }));

    const StyledSection = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    }));

    const PrivacyPolicy = () => {
    return (
        <StyledPrivacyContainer>
        <StyledTitle variant="h3">Privacy Policy</StyledTitle>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            1. Introduction
            </Typography>
            <Typography variant="body1">
            This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our service. Please read this policy carefully. If you do not agree with the terms of this policy, please do not access the service.
            </Typography>
        </StyledSection>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            2. Information We Collect
            </Typography>
            <Typography variant="body1">
            We may collect information about you in a variety of ways, including personal data you provide directly to us, data collected automatically, and information from third-party sources.
            </Typography>
        </StyledSection>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            3. Use of Your Information
            </Typography>
            <Typography variant="body1">
            We may use information we collect about you to:
            <ul>
                <li>Provide, maintain, and improve our services</li>
                <li>Process transactions and send you related information</li>
                <li>Send you marketing and promotional communications</li>
                <li>Respond to your comments, questions, and requests</li>
                <li>Monitor and analyze usage and trends to improve your experience with the service</li>
            </ul>
            </Typography>
        </StyledSection>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            4. Disclosure of Your Information
            </Typography>
            <Typography variant="body1">
            We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
            <ul>
                <li>By Law or to Protect Rights</li>
                <li>Third-Party Service Providers</li>
                <li>Marketing Communications</li>
            </ul>
            </Typography>
        </StyledSection>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            5. Security of Your Information
            </Typography>
            <Typography variant="body1">
            We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that no method of transmission over the internet or method of electronic storage is 100% secure.
            </Typography>
        </StyledSection>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            6. Your Rights
            </Typography>
            <Typography variant="body1">
            Depending on your location, you may have the following rights regarding your personal information:
            <ul>
                <li>The right to access – You have the right to request copies of your personal data.</li>
                <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate.</li>
                <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
            </ul>
            </Typography>
        </StyledSection>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            7. Contact Us
            </Typography>
            <Typography variant="body1">
            If you have any questions about this Privacy Policy, please contact us at [Your Contact Information].
            </Typography>
        </StyledSection>
        </StyledPrivacyContainer>
    );
    };

    export default PrivacyPolicy;
