import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';

function ToggleColorMode({ mode, ToggleColorMode, ...props }) {
  return (
    <Button
      onClick={ToggleColorMode}
      variant="text"             // Minimalistic text button
  color="info"               // Info color
  size="small"               // Small button size
  aria-label="Theme toggle button"  // Accessibility label
  {...props}                 // Spread additional props if necessary
  startIcon={mode === 'dark' ? <WbSunnyRoundedIcon /> : <ModeNightRoundedIcon />}
>

      {mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
    </Button>
  );
}

ToggleColorMode.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default ToggleColorMode;
