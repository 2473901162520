import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Sitemark from './SitemarkIcon';
import ToggleColorMode from './ToggleColorMode';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import PropTypes from 'prop-types';


const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar({ mode, ToggleColorMode }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 7 }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <Sitemark />
            <Box sx={{ display: { xs: 'none', md: 'flex', display: 'none' } }}>
              {/* <Button variant="text" color="info" size="small">
                Features
              </Button> */}
              {/* <Button variant="text" color="info" size="small">
                Testimonials
              </Button> */}
              {/* <Button variant="text" color="info" size="small">
                Highlights
              </Button> */} 
              

              <Button variant="text" color="info" size="small"  onClick={(e) => {
      e.preventDefault();
      window.location.href='/pricing';
      }}>
                Pricing
              </Button>

              <Button variant="text" color="info" size="small" sx={{ minWidth: 0 }} onClick={(e) => {
      e.preventDefault();
      window.location.href='/faq';
      }}>
                FAQ
              {/* </Button>
              <Button
            onClick={ToggleColorMode}          // Function to toggle mode
            variant="text"                     // Text button style
            color="info"                       // Info color
            size="small"                       // Small button size
            startIcon={                         // Conditionally render sun/moon icon
              mode === 'dark' ? <WbSunnyRoundedIcon /> : <ModeNightRoundedIcon />
            }
          >
            {mode === 'dark' ? 'Light Mode' : 'Dark Mode'} {/* Dynamic button text */}
          </Button> */}
              {/* <Button variant="text" color="info" size="small" sx={{ minWidth: 0 }}>
                Blog
              </Button> */}
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 1,
              alignItems: 'center',
            }}
          >
      <Button color="primary" variant="text" size="small" onClick={(e) => {
      e.preventDefault();
      window.location.href='/sign-in';
      }}>
              Sign in
            </Button>
            <Button color="primary" variant="contained" size="small" onClick={(e) => {
      e.preventDefault();
      window.location.href='/sign-up';
      }}>
              Sign up
            </Button>
            <Button color="primary" variant="contained" size="small" onClick={(e) => {
      e.preventDefault();
      window.location.href='/product-listing';
      }}>
              List a Product
            </Button>

          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem>Features</MenuItem>
                <MenuItem>Testimonials</MenuItem>
                <MenuItem>Highlights</MenuItem>
                <MenuItem>Pricing</MenuItem>
                <MenuItem>FAQ</MenuItem>
                <MenuItem>Blog</MenuItem>
                <MenuItem>
                  <Button color="primary" variant="contained" fullWidth>
                    Sign up
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button color="primary" variant="outlined" fullWidth>
                    Sign in
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
