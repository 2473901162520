    import React, { useState } from 'react';
    import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    Box,
    } from '@mui/material';
    import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
    import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
    import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
    import SupportAgentIcon from '@mui/icons-material/SupportAgent';
    import LockOpenIcon from '@mui/icons-material/LockOpen';
    import SmartphoneIcon from '@mui/icons-material/Smartphone';

    const FAQ = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const faqs = [
        {
        question: 'What is the purpose of this website?',
        answer: 'This website allows users to manage their furniture inventory easily.',
        icon: <HelpOutlineIcon />,
        },
        {
        question: 'How can I sign up?',
        answer: 'You can sign up by clicking the "Sign Up" button and filling out the form.',
        icon: <AccessibilityNewIcon />,
        },
        {
        question: 'Is there a mobile version available?',
        answer: 'Currently, we focus on a desktop experience, but mobile compatibility is planned for the future.',
        icon: <SmartphoneIcon />,
        },
        {
        question: 'What kind of support do you provide?',
        answer: 'We offer email support and a help center for frequently asked questions.',
        icon: <SupportAgentIcon />,
        },
        {
        question: 'How do I reset my password?',
        answer: 'You can reset your password through the "Forgot Password?" link on the sign-in page.',
        icon: <LockOpenIcon />,
        },
    ];

    return (
        <Container maxWidth="lg" component="main" sx={{ my: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
            Frequently Asked Questions
        </Typography>
        {faqs.map((faq, index) => (
            <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{
                mb: 2,
                boxShadow: 3,
                borderRadius: 2,
                '&:before': {
                display: 'none',
                },
            }}
            >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
                sx={{
                bgcolor: 'primary.main',
                color: 'white',
                '&:hover': {
                    bgcolor: 'primary.dark',
                },
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton size="small" color="inherit">
                    {faq.icon}
                </IconButton>
                <Typography variant="h6" sx={{ pl: 1 }}>
                    {faq.question}
                </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{faq.answer}</Typography>
            </AccordionDetails>
            </Accordion>
        ))}
        </Container>
    );
    };

    export default FAQ;
