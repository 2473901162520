import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import {
  Box,
  Typography,
  Card,
  CardMedia,
  Chip,
  Grid,
  Avatar,
  AvatarGroup,
  Divider,
  Button,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  AccessTime,
  LocalOffer,
  Stars,
  Home,
  ArrowBack,
  ShoppingCart,
} from '@mui/icons-material';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const AttributeChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const DetailSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const ProductDetailPage = ({ onBack }) => {
  const { productId } = useParams(); // Get productId from URL params
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  // Mock data
  const mockProduct = {
    img: 'https://via.placeholder.com/400',
    modelName: 'Mock Model X1',
    description: 'This is a mock product description for testing purposes.',
    brand: 'MockBrand',
    materials: ['Plastic', 'Metal'],
    purchaseDate: { month: 'January', year: '2023' },
    yearsOfOwnership: 1,
    usageCondition: 'Like New',
    priceOfPurchase: '$499',
    purchasedFrom: 'Mock Store',
    firstOwner: true,
    authors: [
      { name: 'John Doe', avatar: 'https://via.placeholder.com/150' },
      { name: 'Jane Doe', avatar: 'https://via.placeholder.com/150' },
    ],
  };

  useEffect(() => {
    // Simulate an API call delay
    setTimeout(() => {
      setProduct(mockProduct); // Set mock data after the delay
      setLoading(false);
    }, 1000); // Simulate 1 second delay
  }, [productId]); // Runs when productId changes

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!product) {
    return null;
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', padding: 3 }}>
      <Button startIcon={<ArrowBack />} onClick={onBack} sx={{ marginBottom: 2 }}>
        Back to Products
      </Button>

      <StyledCard>
        <CardMedia
          component="img"
          height="400"
          image={product.img}
          alt={product.modelName}
          sx={{ objectFit: 'cover' }}
        />

        <Box sx={{ padding: 3 }}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
            {product.modelName}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <DetailSection>
                <Typography variant="h6" gutterBottom>
                  Description
                </Typography>
                <Typography variant="body1">{product.description}</Typography>
              </DetailSection>

              <DetailSection>
                <Typography variant="h6" gutterBottom>
                  Specifications
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      <strong>Brand:</strong> {product.brand}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Materials:</strong> {product.materials.join(', ')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      <strong>Purchase Date:</strong> {`${product.purchaseDate.month} ${product.purchaseDate.year}`}
                    </Typography>
                    <Typography variant="body2">
                      <strong>First Owner:</strong> {product.firstOwner ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                </Grid>
              </DetailSection>

              <DetailSection>
                <Typography variant="h6" gutterBottom>
                  Product Details
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  <AttributeChip icon={<LocalOffer />} label={product.priceOfPurchase} />
                  <AttributeChip icon={<AccessTime />} label={`${product.yearsOfOwnership} years owned`} />
                  <AttributeChip icon={<Home />} label={product.purchasedFrom} />
                  <AttributeChip icon={<Stars />} label={product.usageCondition} />
                </Box>
              </DetailSection>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                  {product.priceOfPurchase}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" gutterBottom>
                  <strong>Condition:</strong> {product.usageCondition}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Years Owned:</strong> {product.yearsOfOwnership}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                  variant="contained"
                  startIcon={<ShoppingCart />}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Add to Cart
                </Button>
              </Card>
            </Grid>
          </Grid>

          <DetailSection>
            <Typography variant="h6" gutterBottom>
              Seller Information
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <AvatarGroup max={3}>
                {product.authors.map((author, index) => (
                  <Avatar key={index} alt={author.name} src={author.avatar} />
                ))}
              </AvatarGroup>
              <Typography variant="body1">
                {product.authors.map(author => author.name).join(', ')}
              </Typography>
            </Box>
          </DetailSection>
        </Box>
      </StyledCard>
    </Box>
  );
};

export default ProductDetailPage;
