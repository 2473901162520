import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Slider from '@mui/material/Slider';
import PopoverMenu from './PopoverMenu';
import ProTip from './ProTip';
import Blog from './Blog';
import SignUp from './SignUp';
import { Routes, Route, useNavigate } from "react-router-dom"
import LogOut from './Logout';
import SignIn from './SignIn';
import FAQ from './FAQ';
import Pricing from './Pricing';
import { light } from '@mui/material/styles/createPalette';
import ProductListingForm from './ProductListingForm';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import ProductDetailPage from './components/ProductDetailPage';


function Copyright() {
  return (
    <Typography
      variant="body2"
      align="center"
      sx={{
        color: 'text.secondary',
      }}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function App() {
  localStorage.setItem("theme","light")
  localStorage.setItem("themeMode","light")
  const navigate = useNavigate();

  const handleBackToProducts = () => {
    navigate('/products'); // Change this to your products listing route
  };
  return (
    
    // <Container maxWidth="sm">
    //   <div className="my-4">
    //     <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
    //       Material UI Create React App example with Tailwind CSS in TypeScript
    //     </Typography>
    //     <Slider
    //       className="my-4"
    //       defaultValue={30}
    //       classes={{ active: 'shadow-none' }}
    //       slotProps={{ thumb: { className: 'hover:shadow-none' } }}
    //     />
    //     <PopoverMenu />
    //     <ProTip />
    //     <Copyright />
    //   </div>
    // </Container>
    // <Blog/> 
    <div className="App">
      <Routes>
        <Route path="/" element={ <Blog/> } />
        <Route path="sign-up" element={ <SignUp/> } />
        <Route path="sign-in" element={ <SignIn/> } />
        <Route path="logout" element={ <LogOut/>  } /> 
        <Route path="faq" element={ <FAQ/>} />
        <Route path="pricing" element={ <Pricing/>} />
        <Route path="product-listing" element={<ProductListingForm />} />
        <Route path="privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="terms-of-service" element={<TermsOfService/>}/>
       <Route
          path="product-detail/:productId"
          element={<ProductDetailPage onBack={handleBackToProducts} />}
        />
      </Routes>
    </div>
  );
}

