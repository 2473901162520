    import React from 'react';
    import { Box, Typography } from '@mui/material';
    import { styled } from '@mui/material/styles';

    const StyledTermsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 800,
    margin: 'auto',
    padding: theme.spacing(5),
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.1))', // gradient background
    backdropFilter: 'blur(15px)', // acrylic effect
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0px 8px 32px rgba(31, 38, 135, 0.37)', // deep shadow
    border: '1px solid rgba(255, 255, 255, 0.18)', // border for frosted effect
    overflow: 'hidden',
    position: 'relative',
    }));

    const StyledTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    }));

    const StyledSection = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    }));

    const TermsOfService = () => {
    return (
        <StyledTermsContainer>
        <StyledTitle variant="h3">Terms of Service</StyledTitle>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            1. Acceptance of Terms
            </Typography>
            <Typography variant="body1">
            By accessing or using our service, you agree to comply with and be bound by these terms and conditions. 
            If you do not agree with any part of these terms, you must not use our service.
            </Typography>
        </StyledSection>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            2. Modifications
            </Typography>
            <Typography variant="body1">
            We reserve the right to modify these terms at any time. We will provide notice of any changes by 
            updating the "last updated" date at the top of these terms.
            </Typography>
        </StyledSection>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            3. User Responsibilities
            </Typography>
            <Typography variant="body1">
            You are responsible for maintaining the confidentiality of your account and password, and for 
            restricting access to your account. You agree to accept responsibility for all activities that occur 
            under your account or password.
            </Typography>
        </StyledSection>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            4. Intellectual Property
            </Typography>
            <Typography variant="body1">
            All content, trademarks, and other intellectual property rights associated with our service are 
            owned by us or our licensors. You may not reproduce, distribute, or create derivative works without 
            our express written consent.
            </Typography>
        </StyledSection>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            5. Limitation of Liability
            </Typography>
            <Typography variant="body1">
            In no event shall we be liable for any indirect, incidental, special, consequential, or punitive 
            damages arising out of or in connection with your access to or use of our service.
            </Typography>
        </StyledSection>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            6. Governing Law
            </Typography>
            <Typography variant="body1">
            These terms shall be governed by and construed in accordance with the laws of [Your Country]. 
            Any disputes arising in connection with these terms shall be subject to the exclusive jurisdiction 
            of the courts of [Your Country].
            </Typography>
        </StyledSection>

        <StyledSection>
            <Typography variant="h5" gutterBottom>
            7. Contact Us
            </Typography>
            <Typography variant="body1">
            If you have any questions about these terms, please contact us at [Your Contact Information].
            </Typography>
        </StyledSection>
        </StyledTermsContainer>
    );
    };

    export default TermsOfService;
